<template>
  <div>
    <div class="bluehead">
      <div class="row">
        <div style="text-align: center" class="col"><h5>ORGANISATIONS</h5></div>
        <div class="btn-menu">
          <div
          @click="
              exportationVCF = false;
              exportation = false;
              pasDeTelephone = false
            "
            class="btn rounded-circle p-0 mx-1"
            style="background-color: #0000fe"
          >
            <svg
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop2"
              style="color: white"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="white"
              class="bi bi-arrow-down icone-sup"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
              />
            </svg>
          </div>
        </div>
        <a class="btn-menu" href="">
          <router-link 
            class="nav-link routlink rounded-circle p-0 mx-1"
            style="background-color: #0000FE"
           :to="`/organisations/${ajt} `">
            <svg
            style="color:white"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              class="bi bi-plus icone-sup"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
          </router-link>
        </a>
        <span v-if="typeCompte== 'Super Admin'" class="btn-menu" >
          <router-link
            class="nav-link routlink rounded-circle p-0 mx-1"
            style="background-color: #0000FE"
            :to="`/organisations/${modification} `"
          >
            <svg
              style="color: white"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              class="bi bi-pencil-fill icone-sup"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
              />
            </svg>
          </router-link>
        </span>
      </div>
    </div>
    <center v-if="echec">
      <div>Probleme de connexion.Reéssayer svp</div>
      <svg
        @click="
          echec = '0';
          recharger();
        "
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        style="color: blue; cursor: pointer"
        fill="currentColor"
        class="bi bi-bootstrap-reboot"
        viewBox="0 0 16 16"
      >
        <path
          d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8tableau"
        />
        <path
          d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141tableaum0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6tableau"
        />
      </svg>
      <div
        v-if="echec == '0'"
        class="spinner-border text-primary"
        role="status"
      ></div>
    </center>
    <section v-else class="liste text-center container-lg box-center">
      <div v-if="!noresult" class="row">
        <div class="col-3 xorder2 order-2">
          <div v-if="typeCompte== 'Super Admin'" class="d-md-flex mb-2">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              v-model="elementEnAttente"
              class="custom-control-input"
              id="item"
            />
            <label class="custom-control-label p-1" for="item">
              En attente de validation
            </label>
          </div>
          <!--div class="custom-control custom-switch">
            <input
              type="checkbox"
              v-model="elementActive"
              class="custom-control-input"
              id="item22"
            />
            <label class="custom-control-label" for="item22">
              Organisation(s) validé(s)
            </label>
          </!--div-->
        </div>
          <div class="search">
            <span style="display: flex">
              <input
                id="recherche"
                v-model="search"
                type="search"
                class="form-control"
                placeholder="Rechercher..."
                aria-describedby="passwordHelpBlock"
              />
              <!--div @click="recH()" class="btn btn-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                  />
                </svg>
              </!--div-->
            </span>
          </div>
          <div class="mt-2">Nombre de résultat(s): {{filteredOrganisation.length}} </div>
          <hr />
          <div
            v-if="chargement"
            class="spinner-border text-primary"
            style="width: 2rem; height: 2rem"
            role="status"
          >
            <span class="visually-hidden"></span>
          </div>
          <div class="list-scrool">
            <div
              style="text-align: start"
              v-for="item in filteredOrganisation"
              :key="item.index"
              class="border blanc"
            >
              <span
                @click="
                  (selectitem = item),
                    (organisation_id = selectitem.id),
                    exporteur(item)
                "
                class="btn pointille text-left px-0 document col-xl-11 col-10"
                style="width: 100%; height: 100%;"
              >
                
                  <img
                  style="margin: 10px; border-radius: 100%"
                  v-if="item.lien_photo"
                  :src="photo + item.lien_photo"
                  alt="Photo"
                  width="50"
                  height="50"
                  srcset=""
                  class="image-fit"
                />
                <svg
                  style="margin: 10px"
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  class="bi color-icone bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  />
                </svg>
                <span class="text-truncate">{{ item.nom.toUpperCase() }}</span>
                
                
              </span>
              <span class="col-md-1 col-2 bg-dark align-self-start text-white rounded-circle" style="font-size: 9px; padding: 3px 4px"> {{item.id}} </span>
            </div>
          </div>
        </div>
        <div v-if="chargement" class="col page-vide order-1">
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p>chargement des données...</p>
        </div>
        <div v-else class="col xorder1 order-1">
          <div
            style="text-align: center"
            v-if="erreur"
            class="alert alert-danger"
            role="alert"
          >
            {{ msg }}
          </div>
          <div
            style="text-align: center"
            v-else-if="msg2 !== ''"
            class="alert alert-success"
            role="alert"
          >
            {{ msg2 }}
          </div>
          <section class="details">
            <div v-if="typeCompte== 'Super Admin'" class="delete float-right">
              <svg
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop1"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi icone-sup bi-trash-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 
                  4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"
                />
              </svg>
            </div>
            <div class="row">
              <div style="text-align: center" class="col">
                <div>
                  <img
                    class="imageOrg image-fit"
                    :src="photo + selectitem.lien_photo"
                    alt=""
                  />
                </div>
              </div>
              <div class="col">
                <span v-if="selectitem.matricule == ''">
                  Matricule: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Matricule: <span class="element"> {{ selectitem.matricule }} </span>
                </span>
                <br />

                <span v-if="selectitem.nom == ''">
                  Nom: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Nom:
                  <span class="element"> {{ selectitem.nom }} </span> </span
                ><br />

                <span v-if="selectitem.domaine_intervention == ''">
                  Domaine: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Domaine:
                  <span class="element">
                    {{ selectitem.domaine_intervention }}
                  </span> </span
                ><br />

                <span v-if="selectitem.adresse == ''">
                  Adresse: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Adresse:
                  <span class="element"> {{ selectitem.adresse }} </span> </span
                ><br />

                <span v-if="selectitem.pays == ''">
                  Pays: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Pays:
                  <span class="element"> {{ selectitem.pays }} </span> </span
                ><br />
                <span v-if="selectitem.telephone == ''">
                  Téléphone: <span class="element"> Non Fourni </span>
                </span>
                <span v-else>
                  Téléphone:
                  <span class="element">
                    {{ selectitem.telephone }}
                  </span> </span
                ><br />

                <div style="padding: 10px" class="row">
                  <a
                    v-if="selectitem.mail !== ''"
                    :href="Mail + selectitem.mail"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="rondicone">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="currentColor"
                        class="bi bi-envelope"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    v-if="selectitem.facebook !== ''"
                    :href="Lien + selectitem.facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="rondicone">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="currentColor"
                        class="bi bi-facebook"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    v-if="selectitem.whatsapp !== ''"
                    :href="Lienw + selectitem.whatsapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="rondicone">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="currentColor"
                        class="bi bi-whatsapp"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    v-if="selectitem.site_web !== ''"
                    :href="Lien + selectitem.site_web"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="rondicone">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="currentColor"
                        class="bi bi-globe"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col row"></div>
            <hr />
            <div class="description" v-if="selectitem.description == ''">
              <li class="list-item font-weight-bold">{{ selectitem.nom }}</li>
              Description Non Fournie
            </div>
            <div class="description" v-else>
              <li class="list-item font-weight-bold">{{ selectitem.nom }}</li>
              {{ selectitem.description }}
            </div>
            <hr />
            <div class="ajout-participant float-right">
              <svg
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi icone-sup tn bi-person-plus-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
                <path
                  fill-rule="evenodd"
                  d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                />
              </svg>
            </div>
            <section class="membreTab">
              <div style="padding: 10px" class="row">
                <div
                  style="margin: 2px; justify-content: center"
                  v-for="item in selectitem.membres"
                  :key="item.index"
                  class="border sombre"
                >
                  <center
                    class="cartebtn"
                    @click="participant = item"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <img
                      style="margin: 10px; border-radius: 100%"
                      v-if="item.lien_photo"
                      :src="photo + item.lien_photo"
                      alt="Photo"
                      width="50"
                      height="50"
                      srcset=""
                      class="image-fit"
                    />
                    <svg
                      style="margin: 10px"
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      fill="currentColor"
                      class="bi color-icone bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                    <span
                      @click="participant = item"
                      class="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      {{ item.prenom }} {{ item.nom }}
                    </span>
                  </center>
                </div>
              </div>
            </section>

            <!-- Modal pour détails du participant -->
            <div
              class="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">
                      {{ participant.prenom }} {{ participant.nom }}
                    </h5>

                    <svg
                      type="button"
                      @click="voir = true"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </div>
                  <div
                    style="text-align: center"
                    v-if="voir"
                    class="modal-body modaltext"
                  >
                    <center v-if="MSG !== ''">
                      <div class="alert alert-danger">{{ MSG }}</div>
                    </center>

                    <div class="row">
                      <div class="col">
                        <img
                          style="margin: 10px; border-radius: 100%"
                          v-if="participant.lien_photo"
                          :src="photo + participant.lien_photo"
                          alt="Photo"
                          width="100"
                          height="100"
                          class="image-fit"
                          srcset=""
                        />
                        <svg
                          style="margin: 10px"
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          width="100"
                          height="100"
                          fill="currentColor"
                          class="bi color-icone bi-person-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                          <path
                            fill-rule="evenodd"
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                          />
                        </svg>

                        <div>
                          <li class="list-item">
                            <span>
                              <a target="_blank" :href="Mail + participant.mail"
                                ><img
                                  width="15"
                                  height="15"
                                  src="../assets/png/023-email.png"
                                  alt=""
                              /></a>
                              <a
                                target="_blank"
                                :href="Lien + participant.facebook"
                                ><img
                                  width="15"
                                  height="15"
                                  src="../assets/png/019-facebook-1.png"
                                  alt=""
                              /></a>
                              <a
                                target="_blank"
                                :href="Lienw + participant.whatsapp"
                                ><img
                                  width="15"
                                  height="15"
                                  src="../assets/png/021-whatsapp.png"
                                  alt=""
                              /></a>
                              <a
                                target="_blank"
                                :href="Lien + participant.linkedin"
                                ><img
                                  width="15"
                                  height="15"
                                  src="../assets/png/022-linkedin.png"
                                  alt=""
                              /></a>
                            </span>
                          </li>
                        </div>
                      </div>
                      <div style="text-align: start" class="row col">
                        <div v-if="participant.bureau == ''" class="col">
                          <div class="gras">Bureau</div>
                          Non Fourni
                        </div>
                        <div v-else class="col">
                          <div class="gras">Bureau</div>
                          {{ participant.bureau }}
                        </div>

                        <div v-if="participant.fonction == ''" class="col">
                          <div class="gras">Fonction</div>
                          Non Fourni
                        </div>
                        <div v-else class="col">
                          <div class="gras">Fonction</div>
                          {{ participant.fonction }}
                        </div>
                      </div>
                      <div style="text-align: start" class="row col">
                        <div v-if="participant.pays == ''" class="col">
                          <div class="gras">Pays</div>
                          Non Fourni
                        </div>
                        <div v-else class="col">
                          <div class="gras">Pays</div>
                          {{ participant.pays }}
                        </div>

                        <div v-if="participant.ville == ''" class="col">
                          <div class="gras">Ville</div>
                          Non Fourni
                        </div>
                        <div v-else class="col">
                          <div class="gras">Ville</div>
                          {{ participant.ville }}
                        </div>
                      </div>
                      <div style="text-align: start" class="row col">
                        <div v-if="participant.date_adhesion == ''" class="col">
                          <div class="gras">Adhésion</div>
                          Non Fourni
                        </div>
                        <div v-else class="col">
                          <div class="gras">Adhésion</div>
                          {{ participant.date_adhesion }}
                        </div>

                        <div
                          v-if="participant.etat_cotisation == ''"
                          class="col"
                        >
                          <div class="gras">Cotisation</div>
                          Non Fourni
                        </div>
                        <div v-else class="col">
                          <div class="gras">Cotisation</div>
                          {{ participant.etat_cotisation }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <center v-else-if="voir == false">
                    <div class="alert" role="alert">
                      <h4 class="alert-heading">
                        <svg
                          style="color: red"
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          fill="currentColor"
                          class="bi bi-exclamation-triangle"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                          />
                          <path
                            d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                          /></svg
                        ><br />
                        Attention !!!
                      </h4>
                      <p>Ce participant sera retiré</p>
                      <p class="mb-0">
                        <button
                          @click="
                            voir = s;
                            id_membre = participant.id;
                            deleteMembre();
                          "
                          type="button"
                          class="btn btn-outline-danger"
                        >
                          Confirmer
                        </button>
                        <button
                          @click="voir = true"
                          type="button"
                          class="btn btn-outline-primary"
                        >
                          Annuler
                        </button>
                      </p>
                    </div>
                  </center>
                  <center v-else-if="voir == x">
                    <br />
                    <div
                      class="spinner-border text-primary"
                      style="width: 2rem; height: 2rem"
                      role="status"
                    >
                      <span class="visually-hidden"></span>
                    </div>
                  </center>
                  <center v-else-if="voir == 'echec'">
                    <div class="alert alert-success">{{ MSG }}</div>
                  </center>
                  <div class="modal-footer">
                    <button
                      v-if="voir"
                      @click="voir = false"
                      type="button"
                      class="btn btn-danger"
                    >
                      Retirer
                    </button>
                    <button
                      type="button"
                      @click="
                        exporteurM(participant.id);
                        voir = true;
                      "
                      class="btn btnmodal"
                      data-bs-dismiss="modal"
                    >
                      Voir plus
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal  pour l'ajout des membres à l'evenement-->
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Membres</h5>
                    <div
                      @click="makeGetRequest()"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 
                          8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="modal-body modal-ajout-participant">
                    <table class="table">
                      <tbody v-if="loader">
                        <center>
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          ></div>
                        </center>
                      </tbody>
                      <tbody v-else>
                        <tr v-for="item in all" :key="item.index">
                          <td>
                            <img
                              width="20"
                              height="20"
                              src="../assets/png/005-user.png"
                              alt=""
                            />
                          </td>
                          <th scope="row">{{ item.nom }}</th>
                          <th scope="row">{{ item.prenom }}</th>
                          <td>
                            <svg
                              @click="
                                ajoutParticipant(item.id), (loader = true)
                              "
                              style="color: green"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-person-plus-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                              />
                              <path
                                fill-rule="evenodd"
                                d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                              />
                            </svg>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="modal-footer">
                    <button
                      @click="makeGetRequest()"
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal Exportation PDF-->
      <div
        class="modal fade"
        id="staticBackdrop3"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <div class="modal-title" id="staticBackdropLabel">
                Veuillez côcher les champs que vous souhaitez avoir dans votre
                tableau
              </div>
              <svg
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
            <div class="modal-body modaltext">
              <div v-if="!exportation">
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="idChecked"
                    class=""
                    id="idChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="idChecked"
                  >
                    Id
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="matriculeChecked"
                    class=""
                    id="matriculeChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="matriculeChecked"
                  >
                    Matricule
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="nomChecked"
                    class=""
                    id="nomChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="nomChecked"
                  >
                    Nom
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="descriptionChecked"
                    class=""
                    id="descriptionChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="descriptionChecked"
                  >
                    Description
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="domaineChecked"
                    class=""
                    id="domaineChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="domaineChecked"
                  >
                    Domaine
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="dateAdhesionChecked"
                    class=""
                    id="dateAdhesionChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="dateAdhesionChecked"
                  >
                    Date d'adhésion
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="paysChecked"
                    class=""
                    id="paysChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="paysChecked"
                  >
                    Pays
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="villeChecked"
                    class=""
                    id="villeChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="villeChecked"
                  >
                    Ville
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="localiteChecked"
                    class=""
                    id="localiteChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="localiteChecked"
                  >
                    Localité
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="zoneChecked"
                    class=""
                    id="zoneChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="zoneChecked"
                  >
                    Zone
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="telephoneChecked"
                    class=""
                    id="telephoneChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="telephoneChecked"
                  >
                    Téléphone
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="whatsappChecked"
                    class=""
                    id="whatsappChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="whatsappChecked"
                  >
                    WhatsApp
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="mailChecked"
                    class=""
                    id="mailChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="mailChecked"
                  >
                    Mail
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="siteChecked"
                    class=""
                    id="siteChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="siteChecked"
                  >
                    Site Web
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-6 text-left my-2">
                  <input
                    type="checkbox"
                    v-model="facebookChecked"
                    class=""
                    id="facebookChecked"
                  />
                  <label
                    class="col-form-label label-checkbox pl-2 py-0"
                    for="facebookChecked"
                  >
                    Facebook
                  </label>
                </div>
                <div class="col-6 text-left my-2">
                <input
                  type="checkbox"
                  v-model="date_enregistrementChecked"
                  class=""
                  id="date_enregistrementChecked"
                />
                <label
                  class="col-form-label label-checkbox pl-2 py-0"
                  for="date_enregistrementChecked"
                >
                  Date d'Enregistrement
                </label>
              </div>
              </div>
              </div>
              <!--div v-if="supprimer == false" class="row">
                <div style="text-align: center" class="col">
                  <svg
                    style="color: red"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                    />
                    <path
                      d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                    />
                  </svg>
                </div>
                <div style="text-align: start" class="col-8">
                  {{ selectitem.nom }} <br />
                  {{ selectitem.prenom }}
                </div>
              </!--div-->
              <div style="text-align: center; color: green" v-if="exportation">
                <h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-check2-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                    />
                    <path
                      d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                    />
                  </svg>
                  Succès de l'opération
                </h5>
              </div>
            </div>
            <div v-if="!exportation" class="modal-footer">
              <button
                @click="generatePDF()"
                type="button"
                class="btn btn-danger"
              >
                Exporter
              </button>
              <button
                @click="modalExportation = false"
                type="button"
                class="btn btnmodal"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
            <!-- Modal Exportation -->
      <div
        class="modal fade"
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h5 class="modal-title" id="staticBackdropLabel">
                Exportation
              </h5>
              <svg
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
            <div class="modal-body modaltext">
              <!--div v-if="supprimer == false" class="row">
                <div style="text-align: center" class="col">
                  <svg
                    style="color: red"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                    />
                    <path
                      d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                    />
                  </svg>
                </div>
                <div style="text-align: start" class="col-8">
                  {{ selectitem.nom }} <br />
                  {{ selectitem.prenom }}
                </div>
              </!--div-->
              <div class="text-center" v-if="exportationVCF">
                <h6 class="text-danger">
                  Vous pouvez exporter qu'un contact à la fois.
                </h6>
              </div>
              <div class="text-center" v-if="pasDeTelephone">
                <h6 class="text-danger">
                  Cette organisation n'a pas de numéro de téléphone.
                </h6>
              </div>
              <div
                style="text-align: center; color: green"
                v-if="exportation"
              >
                <h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-check2-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                    />
                    <path
                      d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                    />
                  </svg>
                  Succès de l'opération
                </h5>
              </div>
            </div>
            <div v-if="!exportation" class="modal-footer">
              <button
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop3"
                type="button"
                class="btn btn-danger d-block"
                data-bs-dismiss="modal"
              >
                Listes des Organisations
              </button>
              <button
                @click="generateMail()"
                type="button"
                class="btn btn-danger"
              >
                Listes des mails
              </button>
              <!--button
                @click="generateVCF()"
                type="button"
                class="btn btn-danger"
              >
                En VCF
              </!--button>
              <button--
                @click="modalExportation = false"
                type="button"
                class="btn btnmodal"
                data-bs-dismiss="modal"
              >
                Annuler
              </button-->
            </div>
          </div>
        </div>
      </div>

            <!-- Modal confirmation de suppression -->
            <div
              class="modal fade"
              id="staticBackdrop1"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header bg-danger">
                    <h5 class="modal-title" id="staticBackdropLabel">
                      ATTENTION SUPPRESSION
                    </h5>
                    <svg
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </div>
                  <div class="modal-body modaltext">
                    <div v-if="supprimer == false" class="row">
                      <div style="text-align: center" class="col">
                        <svg
                          style="color: red"
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          fill="currentColor"
                          class="bi bi-exclamation-triangle"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                          />
                          <path
                            d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                          />
                        </svg>
                      </div>
                      <div style="text-align: start" class="col-8">
                        {{ selectitem.nom }} <br />
                        {{ selectitem.description }}
                      </div>
                    </div>
                  </div>
                  <div v-if="supprimer == false" class="modal-footer">
                    <button
                      @click="del(), (supprimer = true)"
                      type="button"
                      class="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Confirmer
                    </button>
                    <button
                      type="button"
                      class="btn btnmodal"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div v-else class="pasdelement">
        <div class="search">
          <span style="display: flex">
            <input
              id="recherche"
              v-model="search"
              type="search"
              class="form-control"
              placeholder="Rechercher..."
              aria-describedby="passwordHelpBlock"
            />
            <div @click="recH()" class="btn btn-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg>
            </div>
          </span>
        </div>
        <hr />
        <h4>Aucune oganisation trouvée</h4>
      </div>
    </section>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import VCard from "vcard-creator";
import axios from "axios";
//import RadialBarGraph from 'vue-radial-bargraph';
export var tabElemen = "";
export var choix2 = "";
export default {
  name: "liste_organisation",
  components: {
    // RadialBarGraph
  },
  data: () => {
    return {
      nom: "BILLY",
      prenom: "",
      naissance: "",
      genre: "",
      domaine: "",
      photo: "https://www.rab-tech.com/dev_mode/js/api/",
      profession: "",
      dateAdhesion: "",
      categorie: "",
      fonction: "",
      bureau: "",
      matricule: "",
      ville: "",
      localite: "",
      zone: "",
      telephone: "",
      mail: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
      skype: "",
      personnalite: "",
      organisation: "",
      fonctionOrganisation: "",
      date_enregistrement: "12/12/20",
      chargement: true,
      loader: true,
      organisation_id: "",
      mod: false,
      Etatdeschamps: "",
      reponx: [],
      selectitem: "",
      Tel: "tel:",
      Mail: "mailto:",
      Lienw: " https://wa.me/*",
      Lien: "",
      modification: "1",
      ajt: "0",
      search: "",
      filtreur: "nom",
      all: [],
      ajouteur: "",
      participant: "",
      reponx_code: "",
      voir: true,
      ajt1: false,
      msg: "",
      erreur: false,
      msg2: "",
      supprimer: false,
      id_membre: "",
      MSG: "",
      id_import: "",
      echec: false,
      noresult: false,
      elementNom: "",
      elementDomaine: "",
      elementDescription: "",
      elementMatricule: "",
      elementVille: "",
      elementLocalite: "",
      elementZone: "",
      elementTelephone: "",
      elementMail: "",
      elementWhatsapp: "",
      elementAdresse: "",
      elementPays: "",
      typeCompte: "",
      elementEnAttente: false,
      elementActive: false,
      modalExportation: false,
      exportation: false,
      tabHeadChecked: [],
      tabBodyChecked: [],
      nomChecked: true,
      idChecked: false,
      domaineChecked: false,
      matriculeChecked: true,
      villeChecked: false,
      localiteChecked: false,
      zoneChecked: false,
      telephoneChecked: true,
      mailChecked: true,
      whatsappChecked: false,
      paysChecked: false,
      adresseChecked: false,
      date_adhesionChecked: false,
      date_enregistrementChecked: false,
      descriptionChecked: false,
      siteChecked: true,
      facebookChecked: false,
      exportationVCF: false,
      pasDeTelephone: false,
    };
  },
  methods: {
    firstElement: function () {
      this.selectitem = this.reponx[0];
      this.exporteur(this.selectitem);
      this.organisation_id = this.selectitem.id;
      this.allmembre();
    },
    ActiverChamps: function () {
      var champ = document.getElementById("champsD");
      champ.disabled = !champ.disabled;
    },
    makeGetRequest: async function () {
      let lien = document.URL;
      let lien2 = lien.split("/").pop();
      if (lien2 !== "0") {
        this.id_import = lien2;
      }
      const url =
        "https://www.rab-tech.com/dev_mode/js/api/get_all_organisations.php?id=" +
        this.id_import;
      axios
        .get(url)
        .then((response) => {
          this.reponx_code = response.data.code;
          this.reponx = response.data.organisations;
        })
        .catch((error) => {
          console.log(error);
          this.echec = true;
        })
        .finally(() => {
          if (this.reponx_code == 200) {
            this.chargement = false;
            this.firstElement();
            this.echec = false;
          }
          if (this.reponx_code == 300) {
            this.chargement = false;
            this.echec = false;
            this.noresult = true;
          }
        });
    },
    recharger() {
      //window.location.reload();
      this.makeGetRequest();
    },
    recH: async function () {
      this.noresult = false;
      this.chargement = true;

      const url =
        "https://www.rab-tech.com/dev_mode/js/api/get_all_organisations.php?theme=" +
        this.search;
      const res = await axios.get(url);
      this.reponx = res.data.organisations;
      this.reponx_code = res.data.code;
      if (this.reponx_code == 200) {
        this.chargement = false;
        this.firstElement();
        this.echec = false;
      }
      if (this.reponx_code == 300) {
        this.chargement = false;
        this.echec = false;
        this.noresult = true;
      }
    },
    exporteur(selection) {
      tabElemen = selection;
      return console.log(selection);
    },
    exporteurM(selection) {
      //choix2=selection
      let vm = this;
      vm.$router.push({ path: `/membre-detail/${selection} ` });
      return console.log(selection);
    },
    del: async function () {
      this.chargement = true;
      this.erreur = false;
      const SUP = new FormData();
      SUP.set("id", this.selectitem.id);
      axios
        .post(
          "https://www.rab-tech.com/dev_mode/js/api/delete_organisation.php",
          SUP
        )
        .then((response) => {
          console.log(response);
          this.reponx_code = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.msg = "Echec de la supression. Veillez réessayer svp !";
          this.erreur = true;
          this.chargement = false;
        })
        .finally(() => {
          if (this.reponx_code == "201") {
            this.supprimer = true;
            this.chargement = false;
            this.makeGetRequest();
            this.supprimer = false;
            this.msg2 = "L'organisation à bien été supprimée.";
            setTimeout(() => {
              this.msg2 = "";
            }, 3500);
          } else {
            this.msg = "Echec de la supression. Veillez réessayer svp !";
            this.erreur = true;
            this.chargement = false;
            setTimeout(() => {
              this.msg = "";
            }, 3500);
          }
        });
    },
    deleteMembre: async function () {
      const SUP = new FormData();
      SUP.set("id_membre", this.id_membre);
      axios
        .post(
          "https://www.rab-tech.com/dev_mode/js/api/delete_membre_organisation.php",
          SUP
        )
        .then((response) => {
          console.log(response);
          this.reponx_code = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.MSG = "Echec de la supression. Veillez réessayer svp !";
          this.voir = "echec";
        })
        .finally(() => {
          if (this.reponx_code == "201") {
            window.location.reload();
          } else {
            this.MSG = "Echec de la supression. Veillez réessayer svp !";
            this.voir = "echec";
          }
        });
    },
    allmembre: async function () {
      this.organisation_id = this.selectitem.id;
      const rex = await axios.get(
        "https://www.rab-tech.com/dev_mode/js/api/get_all_membres.php?id_organisation=" +
          this.organisation_id
      );
      this.all = rex.data.membres;
      this.loader = false;
    },
    ajoutParticipant(info_id) {
      this.loader = true;
      this.ajouteur = info_id;

      const AJOUT = new FormData();
      AJOUT.set("id_organisation", this.selectitem.id);
      AJOUT.set("id_membre", this.ajouteur);
      axios
        .post(
          "https://www.rab-tech.com/dev_mode/js/api/add_membre_to_organisation.php",
          AJOUT
        )
        .then((response) => {
          console.log(response);
          this.reponx_code = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.msg = "Echec de l'ajout du membre. Veillez réessayer svp !";
          this.erreur = true;
          this.chargement = false;
          this.loader = false;
        })
        .finally(() => {
          if (this.reponx_code == "201") {
            this.allmembre();

            //window.location.reload();
          } else {
            this.msg = "Echec de l'ajout du membre. Veillez réessayer svp !";
            this.erreur = true;
            this.chargement = false;
            this.loader = false;
          }
        });
    },
    generatePDF(){
      if (this.idChecked) {
        this.tabHeadChecked.push({ content: "ID", colSpan: 2 });
      }
      if (this.matriculeChecked) {
        this.tabHeadChecked.push({ content: "MATRICULE", colSpan: 2 });
      }
      if (this.nomChecked) {
        this.tabHeadChecked.push({ content: "NOM", colSpan: 2 });
      }
      if (this.descriptionChecked) {
        this.tabHeadChecked.push({ content: "DESCRIPTION", colSpan: 2 });
      }
      if (this.domaineChecked) {
        this.tabHeadChecked.push({ content: "DOMAINE", colSpan: 2 });
      }
      if (this.date_adhesionChecked) {
        this.tabHeadChecked.push({ content: "D. ADHESION", colSpan: 2 });
      }
      if (this.adresseChecked) {
        this.tabHeadChecked.push({ content: "ADRESSE", colSpan: 2 });
      }
      if (this.paysChecked) {
        this.tabHeadChecked.push({ content: "PAYS", colSpan: 2 });
      }
      if (this.villeChecked) {
        this.tabHeadChecked.push({ content: "VILLE", colSpan: 2 });
      }
      if (this.localiteChecked) {
        this.tabHeadChecked.push({ content: "LOCALITE", colSpan: 2 });
      }
      if (this.zoneChecked) {
        this.tabHeadChecked.push({ content: "ZONE", colSpan: 2 });
      }
      if (this.telephoneChecked) {
        this.tabHeadChecked.push({ content: "TELEPHONE", colSpan: 2 });
      }
      if (this.whatsappChecked) {
        this.tabHeadChecked.push({ content: "WHATSAPP", colSpan: 2 });
      }
      if (this.mailChecked) {
        this.tabHeadChecked.push({ content: "MAIL", colSpan: 2 });
      }
      if (this.siteChecked) {
        this.tabHeadChecked.push({ content: "SITE WEB", colSpan: 2 });
      }
      if (this.facebookChecked) {
        this.tabHeadChecked.push({ content: "FACEBOOK", colSpan: 2 });
      }
      if (this.date_enregistrementChecked) {
        this.tabHeadChecked.push({ content: "D. ENREGISTREMENT", colSpan: 2 });
      }
      //let Color = "black";
      if (this.tabHeadChecked.length > 6) {
        var doc = new jsPDF("l", "mm", "a1");
      } else {
        doc = new jsPDF("l", "mm", "a4");
      }
      doc.autoTable({
        head: [this.tabHeadChecked],
        //head: [[{ content: 'Id', colSpan: 2 }, { content: 'Nom', colSpan: 2 }, { content: 'Prénom', colSpan: 2 }]],
        //theme :  'grid'    ,
        // columnStyles: { 2: { halign: 'center' } },
        //styles : { lineColor: Color = 2, lineWidth: 2   } ,
      });
      for (let i = 0; i < this.filteredOrganisation.length; i++) {
        const element = this.filteredOrganisation[i];
        this.tabBodyChecked = [];
        if (this.idChecked) {
          this.tabBodyChecked.push({
            content: element.id,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.matriculeChecked) {
          this.tabBodyChecked.push({
            content: element.matricule,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.nomChecked) {
          this.tabBodyChecked.push({
            content: element.nom,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.descriptionChecked) {
          this.tabBodyChecked.push({
            content: element.description,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.domaineChecked) {
          this.tabBodyChecked.push({
            content: element.domaine_intervention,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.date_adhesionChecked) {
          this.tabBodyChecked.push({
            content: element.date_adhesion,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.adresseChecked) {
          this.tabBodyChecked.push({
            content: element.adresse,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.paysChecked) {
          this.tabBodyChecked.push({
            content: element.pays,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.villeChecked) {
          this.tabBodyChecked.push({
            content: element.ville,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.localiteChecked) {
          this.tabBodyChecked.push({
            content: element.localite,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.zoneChecked) {
          this.tabBodyChecked.push({
            content: element.zone,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.telephoneChecked) {
          this.tabBodyChecked.push({
            content: element.telephone,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.whatsappChecked) {
          this.tabBodyChecked.push({
            content: element.whatsapp,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.mailChecked) {
          this.tabBodyChecked.push({
            content: element.mail,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.siteChecked) {
          this.tabBodyChecked.push({
            content: element.site_web,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.facebookChecked) {
          this.tabBodyChecked.push({
            content: element.facebook,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        if (this.date_enregistrementChecked) {
          this.tabBodyChecked.push({
            content: element.date_enregistrement,
            colSpan: 2,
            styles: { halign: "left" },
          });
        }
        //const elementID = this.filteredMembre[i].id;
        //const elementNOM = this.filteredMembre[i].nom;
        //const elementPRENOM = this.filteredMembre[i].prenom;
        doc.autoTable({
          body: [
            this.tabBodyChecked,
            //[{ content: element.id, colSpan: 2, rowSpan: 1, styles: { halign: 'left' } },
            //{ content: element.nom, colSpan: 2, rowSpan: 1, styles: { halign: 'left' } },
            //{ content: element.prenom, colSpan: 2, rowSpan: 1, styles: { halign: 'left' } }, ],
            //[element.id, element.nom, element.prenom]
            // ...
          ],
        });
      }
      /*for (let val of this.filteredMembre) {
      doc.autoTable({
      head: [['Id', 'Nom', 'Prénom']],
      body: [
        [val.id, val.nom, val.prenom]
        // ...
      ],
    })
    }*/
      /*doc.autoTable({
      head: [[
        {content: 'Name', colSpan: 2}, {content: 'Email', colSpan: 2}, {content: 'Country', colSpan: 2}]],
      body: [
        [{ content: 'David', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
        { content: 'david@example.com', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
        { content: 'Sweden', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } }],
        [{ content: 'Castille', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
        { content: 'castille@example.com', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
        { content: 'Spain', colSpan: 1, rowSpan: 1, styles: { halign: 'left' } } ],
        // ...
      ],
    })*/
      doc.save("Lites des Organisations.pdf");
      this.exportation = true;
      /*const doc = new jsPDF();
      doc.autoTable({
      head: [['Name', 'Email', 'Country']],
      body: [
        ['David', 'david@example.com', 'Sweden'],
        ['Castille', 'castille@example.com', 'Spain'],
        // ...
      ],
    });

    doc.save('table.pdf');*/
    },
    generateMail() {
      var vraiElement = [];
      for (let i = 0; i < this.filteredOrganisation.length; i++) {
        const elementTxt = this.filteredOrganisation[i];
        let mailExport = "";
        // Some variables
        mailExport = elementTxt.mail.replace(/ /g, "");
        if (mailExport) {
          vraiElement.push(
          elementTxt.nom +
            " " +
            "<" +
            mailExport +
            ">" +
            "\n"
        );
        }
      }
      var link = document.createElement("a");
      link.href = "data:text/plain;charset=UTF-8," + escape(vraiElement);
      link.download = "Listes des mails.txt";
      link.click();
      this.exportation = true;
    },
    generateVCF(){
      const myVCardVCF = new VCard();
      var elVcf = "";
      if (this.filteredOrganisation.length == 1) {
        elVcf = this.filteredOrganisation[0];
        if (!(elVcf.telephone=="")) {
        myVCardVCF
          // Add personal data
          .addName(elVcf.nom)
          // Add work data
          //.addCompany(elVcfNomOrganisation)
          //.addJobtitle(elVcf.profession)
          .addRole(elVcf.description)
          .addEmail(elVcf.mail)
          .addPhoneNumber(elVcf.telephone, "PREF;WORK")
          .addPhoneNumber(elVcf.whatsapp, "WORK")
          .addAddress(
            "",
            "",
            elVcf.localite,
            elVcf.ville,
            "",
            "",
            elVcf.pays
          )
          .addURL([elVcf.site_web, elVcf.facebook])
          //.addURL(elVcf.linkedin)
          //.addURL(elVcf.skype);
        var linkVCF = document.createElement("a");
        linkVCF.href = "data:text/plain;charset=UTF-8," + escape(myVCardVCF);
        linkVCF.download = "Contact de " + elVcf.nom + ".vcf";
        linkVCF.click();
        this.exportation = true;
        }else {
          this.pasDeTelephone= true;
        }
      } else {
        this.exportationVCF = true;
      }
    },
    rechercher: function () {
      return this.reponx.filter((item) => {
        item.nom.toLowerCase().includes(this.search.toLowerCase());
        this.elementNom = item.nom
          .toLowerCase()
          .includes(this.search.toLowerCase());
        if (this.elementActive==true) {
                return item.is_valide.toLowerCase().includes("1");
              }else {
              if(this.elementEnAttente== true) {
                return item.is_valide.toLowerCase().includes("0");
              }else{  
        if (this.elementNom) {
          return this.elementNom;
        } else {
          this.elementDomaine = item.domaine_intervention
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementDescription = item.description
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementAdresse = item.adresse
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementTelephone = item.telephone
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementLocalite = item.localite
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementZone = item.zone
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementWhatsapp = item.whatsapp
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementMail = item.mail
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementVille = item.ville
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementPays = item.pays
            .toLowerCase()
            .includes(this.search.toLowerCase());
          this.elementMatricule = item.id
            .toLowerCase()
            .includes(this.search.toLowerCase());
          /*if (
            !(item.infos_organisation == null) &&
            item.infos_organisation.nom
          ) {
            this.elementNomOrganisation = item.infos_organisation.nom
              .toLowerCase()
              .includes(this.search.toLowerCase());
          }*/

          if (this.elementTelephone) {
            return this.elementTelephone;
          }
          if (this.elementWhatsapp) {
            return this.elementWhatsapp;
          }
          if (this.elementDomaine) {
            return this.elementDomaine;
          }
          if (this.elementAdresse) {
            return this.elementAdresse;
          }
          if (this.elementLocalite) {
            return this.elementLocalite;
          }
          if (this.elementMail) {
            return this.elementMail;
          }
          if (this.elementVille) {
            return this.elementVille;
          }
          if (this.elementPays) {
            return this.elementPays;
          }
          if (this.elementDescription) {
            return this.elementDescription;
          }
          if (this.elementZone) {
            return this.elementZone;
          }
          if (this.elementMatricule) {
            return this.elementMatricule;
          }
        }
        }
        }
      });
    },
  },
  created() {
    this.makeGetRequest();
    let elementUser = JSON.parse(sessionStorage.getItem("authUser"));
    this.typeCompte = elementUser.type;
  },
  computed: {
    filteredOrganisation() {
      return this.rechercher();
    },
    monFiltre: function () {
      return this.reponx.filter((item) => {
        if (this.filtreur == "nom") {
          return item.nom.toLowerCase().match(this.search.toLowerCase());
        }
        if (this.filtreur == "domaine") {
          return item.domaine_intervention
            .toLowerCase()
            .match(this.search.toLowerCase());
        }
        if (this.filtreur == "telephone") {
          return item.telephone.match(this.search);
        }
        if (this.filtreur == "localite") {
          return item.localite.toLowerCase().match(this.search.toLowerCase());
        }
        if (this.filtreur == "pays") {
          return item.pays.toLowerCase().match(this.search.toLowerCase());
        }
        if (this.filtreur == "ville") {
          return item.ville.toLowerCase().match(this.search.toLowerCase());
        }
      });
    },
  },
};
</script>
